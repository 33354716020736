export const flightTargets = [
  {
    name: "Deutschland",
    targets: [
      {
        city: "Berlin",
        region_tname: "",
        location_lat: 52.35689,
        subregion_tflags: 0,
        subregion_type: "",
        location_type: "AIRP",
        location_key: "EU.DE.BE.BER.BER",
        region_tflags: 0,
        location_tflags: 0,
        region: "Berlin",
        region_type: "ADM1",
        supports_rail: false,
        subregion_tname: "",
        subregion: "",
        location_lon: 13.49645,
        level: 1,
        priority: 1,
        iata: "BER",
        location: "Berlin Brandenburg",
        country_key: "EU.DE",
        alias: "",
        country_code: "DE",
        country: "Deutschland",
        location_tname: "",
      },
      {
        location_key: "EU.DE.HH.HAM",
        country_key: "EU.DE",
        location_lon: 10.01534,
        subregion_tflags: 0,
        priority: 0,
        location: "Hamburg",
        location_tname: "",
        city: "",
        country_code: "DE",
        country: "Deutschland",
        location_lat: 53.57532,
        subregion_tname: "",
        region: "Hamburg",
        location_type: "PPLA",
        alias: "",
        subregion: "",
        level: 0,
        region_tflags: 0,
        subregion_type: "",
        location_tflags: 0,
        region_tname: "",
        iata: "HAM",
        supports_rail: false,
        region_type: "ADM1",
      },
    ],
  },
  {
    name: "Griechenland",
    targets: [
      {
        subregion: "",
        city: "Kos",
        region_type: "ADM1",
        country_code: "GR",
        region_tname: "",
        supports_rail: false,
        subregion_tflags: 0,
        location_lon: 27.09167,
        subregion_type: "",
        subregion_tname: "",
        region: "Notio Egeo",
        location_lat: 36.79334,
        country: "Griechenland",
        country_key: "EU.GR",
        location_tflags: 0,
        region_tflags: 0,
        priority: 0,
        location_key: "EU.GR.L.KGS.KGS",
        alias: "",
        location_tname: "",
        level: 0,
        location: "Kos",
        iata: "KGS",
        location_type: "AIRP",
      },
      {
        location: "Heraklion Flughafen",
        iata: "HER",
        subregion_tname: "",
        subregion: "Kreta",
        location_tname: "",
        region_tflags: 0,
        location_lat: 35.33972,
        region: "Kreta",
        region_tname: "",
        location_lon: 25.1803,
        location_tflags: 0,
        country: "Griechenland",
        subregion_tflags: 0,
        alias: "",
        location_key: "EU.GR.M.CRET.HER.HER",
        priority: 2,
        level: 0,
        country_key: "EU.GR",
        subregion_type: "ISL",
        country_code: "GR",
        region_type: "ADM1",
        supports_rail: false,
        location_type: "AIRP",
        city: "Heraklion",
      },
      {
        location_key: "EU.GR.L.RHO.RHO",
        country_key: "EU.GR",
        location_lon: 28.08619,
        subregion_tflags: 0,
        priority: 1,
        location: "Rhodos",
        location_tname: "",
        city: "Rhodos",
        country_code: "GR",
        country: "Griechenland",
        location_lat: 36.40542,
        subregion_tname: "",
        region: "Notio Egeo",
        location_type: "AIRP",
        alias: "",
        subregion: "",
        level: 0,
        region_tflags: 0,
        subregion_type: "",
        location_tflags: 0,
        region_tname: "",
        iata: "RHO",
        supports_rail: false,
        region_type: "ADM1",
      },
    ],
  },
  {
    name: "Spanien",
    targets: [
      {
        country_code: "ES",
        subregion_tname: "",
        location_type: "AIRP",
        iata: "FUE",
        region_tname: "",
        location_lon: -13.86376,
        level: 0,
        supports_rail: false,
        location: "Fuerteventura",
        alias: "",
        region_tflags: 0,
        subregion: "Fuerteventura",
        subregion_tflags: 0,
        priority: 1,
        location_tname: "",
        country_key: "EU.ES",
        location_tflags: 0,
        country: "Spanien",
        region: "Kanarische Inseln",
        region_type: "ADM1",
        location_lat: 28.45272,
        subregion_type: "ISL",
        location_key: "EU.ES.CN.FUER.FUE.FUE",
        city: "Fuerteventura",
      },
      {
        region_type: "ADM1",
        city: "Las Palmas de Gran Canaria",
        subregion_tname: "",
        country_key: "EU.ES",
        location_tflags: 0,
        subregion: "Gran Canaria",
        location_lon: -15.38659,
        location_lat: 27.93189,
        country_code: "ES",
        region_tname: "",
        location_type: "AIRP",
        iata: "LPA",
        location_tname: "",
        region_tflags: 0,
        priority: 1,
        location_key: "EU.ES.CN.CANA.LPA.LPA",
        level: 0,
        alias: "",
        location: "Gran Canaria",
        supports_rail: false,
        subregion_type: "ISL",
        country: "Spanien",
        subregion_tflags: 0,
        region: "Kanarische Inseln",
      },
      {
        region_type: "ADM1",
        city: "Palma de Mallorca",
        subregion_tname: "",
        country_key: "EU.ES",
        location_tflags: 0,
        subregion: "Mallorca",
        location_lon: 2.73333,
        location_lat: 39.55,
        country_code: "ES",
        region_tname: "",
        location_type: "AIRP",
        iata: "PMI",
        location_tname: "",
        region_tflags: 0,
        priority: 10,
        location_key: "EU.ES.PM.MALL.PMI.PMI",
        level: 0,
        alias: "",
        location: "Son San Juan",
        supports_rail: false,
        subregion_type: "ISL",
        country: "Spanien",
        subregion_tflags: 0,
        region: "Balearen",
      },
      {
        country_code: "ES",
        subregion_tname: "",
        location_type: "AIRP",
        iata: "TFS",
        region_tname: "",
        location_lon: -16.57249,
        level: 0,
        supports_rail: false,
        location: "Aeropuerto de Tenerife Sur",
        alias: "",
        region_tflags: 0,
        subregion: "Teneriffa",
        subregion_tflags: 0,
        priority: 1,
        location_tname: "",
        country_key: "EU.ES",
        location_tflags: 0,
        country: "Spanien",
        region: "Kanarische Inseln",
        region_type: "ADM1",
        location_lat: 28.04448,
        subregion_type: "ISL",
        location_key: "EU.ES.CN.TENE.TCI.2517111.TFS",
        city: "Granadilla de Abona",
      },
    ],
  },
  {
    name: "Türkei",
    targets: [
      {
        region_type: "ADM1",
        city: "Antalya",
        subregion_tname: "",
        country_key: "AS.TR",
        location_tflags: 0,
        subregion: "",
        location_lon: 30.80046,
        location_lat: 36.89873,
        country_code: "TR",
        region_tname: "",
        location_type: "AIRP",
        iata: "AYT",
        location_tname: "",
        region_tflags: 0,
        priority: 0,
        location_key: "AS.TR.07.AYT.AYT",
        level: 0,
        alias: "",
        location: "Antalya",
        supports_rail: false,
        subregion_type: "",
        country: "T\u00fcrkei",
        subregion_tflags: 0,
        region: "Antalya",
      },
      {
        location_key: "AS.TR.35.IZM.ADB",
        country_key: "AS.TR",
        location_lon: 27.15695,
        subregion_tflags: 0,
        priority: 0,
        location: "Izmir Adnan Menderes",
        location_tname: "",
        city: "\u0130zmir",
        country_code: "TR",
        country: "T\u00fcrkei",
        location_lat: 38.29239,
        subregion_tname: "",
        region: "Izmir",
        location_type: "AIRP",
        alias: "",
        subregion: "",
        level: 0,
        region_tflags: 0,
        subregion_type: "",
        location_tflags: 0,
        region_tname: "",
        iata: "ADB",
        supports_rail: false,
        region_type: "ADM1",
      },
    ],
  },
];
