<template lang="">
    <div class="columns package-items">
      <div class="column">
        <p class="label">By:</p>
        <div class="bm-input-wrap">
          <input type="text" autocomplete="off" v-model="searchTermDeparture" :placeholder="selectedDepartureNameValue" @input="getDataDepartureFlight()">
            <ul v-if="searchFlightsDeparture?.length">
            <!-- li>
              <p>
                {{ searchFlightsDeparture?.length }}
                von {{ searchFlightsDeparture?.length }}
              </p>
            </li -->
            <li v-for="flights in searchFlightsDeparture" :key="flights.name" @click="selectFlightDeparture(flights.iata, flights.location)">
              <div class="results">
                <div class="results-image-wrap">
                  <img :src="planeIcon">
                </div>
                <div class="results-content-wrap">
                  <p class="is-clickable country-row">
                    <span v-if="flights.region">
                      <b>{{ flights.region }}</b><br/>
                    </span>
                    <span v-if="flights.city">
                        {{ flights.city }} ({{ flights.location }})
                    </span>
                  </p>
                  <p class="is-clickable iata-row ">
                    {{ flights.iata }} - {{ flights.country }}
                  </p>
                </div>
              </div>
            </li>
            </ul>
        </div>
      </div>
      <!-- div class="column package-item-first">
        <p class="label">Nach:</p>
        <div class="bm-input-wrap">
          <div class="button" @click="toggleDropDown()">
           {{ searchTermArrivalNameValue }}
          </div>
          <div v-if="dropDown" class="results-dropdown">
            <div v-for="flights in flightTargets" :key="flights.name">
              <div v-if="flights.targets.length > 0">
                <div class="result-headline">{{ flights.name }}</div>
                  <div v-for="flight in flights.targets" :key="flight.iata"
                  class="result-item"
                  @click="selectFlightArrival(flight.iata), selectArrivalName(flight.location)">
                    <div class="results-image-wrap">
                      <img :src="planeIcon">
                    </div>
                    <div class="results-content-wrap">
                      <p class="is-clickable country-row">
                        <span v-if="flight.region">
                          <b>{{ flight.region }}</b><br/>
                        </span>
                        <span v-if="flight.location">
                          {{ flight.subregion }} ({{ flight.location }})
                        </span>
                      </p>
                      <p class="is-clickable iata-row ">
                        {{ flight.iata }} - {{ flight.country }}
                      </p>
                    </div>
                  </div>
            </div>
          </div>
          </div>
        </div>
      </div -->
      <div class="column package-item-second">
        <p class="label">Date:</p>
        <vuedatepicker v-model="datePackage" :range="true" :clearable="true" locale="de-DE" :format-locale="de" :min-date="minDate" format="dd.MM.yyyy" preview-format="dd.MM.yyyy" model-type="dd.MM.yyyy" input-class-name="input" auto-apply="" placeholder="Datum auswählen" @update:model-value="setDatePackage" :enable-time-picker="false">
          <template #input-icon="">
            <img class="input-slot-image" alt="Select date" :src="calendarIcon">
          </template>
        </vuedatepicker>
      </div>
      <div class="column package-item-third">
        <p class="label">Persons (Adults):</p>
        <div class="bm-input-wrap">
          <div>
            <select class="input" v-model="selectedPeoplePackage">
              <option v-for="n in 10" :key="n">{{ n }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="column package-item-fourth">
        <p class="label">&nbsp;</p>
        <div class="bm-search-button" disabled="">
          <a class="navbar-item" :href="finalUrlPackage">
            Search package tours
          </a>
        </div>
      </div>
    </div>
</template>
<script>
import { ref, computed } from 'vue'
import { de } from 'date-fns/locale'
import VueDatePicker from '@vuepic/vue-datepicker'
import { flightTargets } from '@/globals/flighttargets.js'
import axios from 'axios'

export default {
  components: { VueDatePicker },
  setup() {
    const calendarIcon = ref(require('@/assets/images/calendar-icon.svg'))
    const planeIcon = ref(require('@/assets/images/plane-icon.svg'))

    let searchTermDeparture = ref('')
    let selectedDeparture = ref('SCN')
    let selectedDepartureNameValue = ref('SCN - Saarbrücken')
    let departureFlights = ref([])

    const today = ref(new Date())
    const minDate = ref(new Date(today.value.getFullYear(), today.value.getMonth(), today.value.getDate() + 4)) 

    let searchTermPackageFrom = ref('')
    let searchTermArrival = ref('')
    let selectedPackageNameValueFrom = ref('SCN - Saarbrücken')
    let searchTermArrivalNameValue = ref('Verfügbare Ziele')
    let packagesData = ref('')
    let datePackage = ref('')
    let selectedPeoplePackage = ref('2')
    let selectedArrival = ref('')
    let selectedArrivalNameValue = ref('Nach *')
    let regionId = ref('619')
    let dropDown = ref(false)

    const handleJsonpResponse = (jsonpResponse) => {
      let jsonResponse = jsonpResponse.substring(jsonpResponse.indexOf("(") + 1, jsonpResponse.lastIndexOf(")"))
      let jsonData = JSON.parse(jsonResponse)
      return jsonData
    }

    const getDataPackageFrom = async () => {
      try {
        const result = await axios.get(`https://ibe.specials.de/?action=suggest&product=package&agent=991784&departues=&searchTerm=${searchTermPackageFrom.value}`)
        packagesData.value = result.data
      } catch (error) {
        console.log(error)
      }
    }

    const searchPackageFrom = computed(() => {
      if (searchTermPackageFrom.value === '') {
        return []
      }

      let matches = 0
      const myData = JSON.parse(JSON.stringify(packagesData.value))

      return myData.locationList?.filter(data => {
        if (data.name.toLowerCase().includes(searchTermPackageFrom.value.toLowerCase())
          && matches < 10 || data.region.name.toLowerCase().includes(searchTermPackageFrom.value.toLowerCase())
          && matches < 100) {
          matches++
          return data
        }
      })
    })

    const getDataDepartureFlight = async () => {
      try {
        const result = await axios.get(`https://api.specials.de/flight/suggest.jsonp?suggest=${searchTermDeparture.value}`)
        departureFlights.value = handleJsonpResponse(result.data)
      } catch (error) {
        console.log(error)
      }
    }

    const selectFlightDeparture = (itata, location) => {
      selectedDeparture.value = itata
      selectedDepartureNameValue.value = location
      searchTermDeparture.value = ''
    }

    const searchFlightsDeparture = computed(() => {
      if (searchTermDeparture.value === '') {
        return []
      }
      return departureFlights.value.filter(flight => flightFilter(flight, searchTermDeparture.value))
    })

    const flightFilter = (flight, input) => {
      input = input.toLowerCase()
      let subregion = flight.subregion.toLowerCase()
      let iata = flight.iata.toLowerCase()
      let location = flight.location.toLowerCase()
      let country = flight.country.toLowerCase()
      let city = flight.city.toLowerCase()

      if (iata.includes(input)
        || subregion.includes(input)
        || location.includes(input)
        || country.includes(input)
        || city.includes(input)) {
        return flight;
      }
    }

    const selectPackageFrom = (packages) => {
      selectedPackageNameValueFrom.value = packages?.name
      selectedArrivalNameValue.value = packages?.location
      regionId.value = packages?.code
      searchTermPackageFrom.value = ''
    }

    const setDatePackage = (value) => {
      datePackage.value = value
    }

    const selectFlightArrival = (flight) => {
      selectedArrival.value = flight
      searchTermArrival.value = ''
    }

    const selectArrivalName = (arrival) => {
      selectedArrivalNameValue.value = arrival
      searchTermArrivalNameValue.value = arrival
      dropDown.value = false
    }

    const toggleDropDown = () => {
      if (dropDown.value) {
        dropDown.value = false
      } else {
        dropDown.value = true
      }
    }

    const searchFlightsArrivals = () => {
      if (searchTermArrival.value.length > 1) {
        return flightTargets.map(flight => {
          let filteredTargets = flight.targets.filter(target => flightFilter(target, searchTermArrival.value))
          flight.targets = filteredTargets
          return flight;
        })
      } else {
        return flightTargets;
      }

    }

    const finalUrlPackage = computed(() => {
      return `https://www.flughafen-saarbruecken.de/en/passagiere-besucher/fly/book/book-flight-and-hotel/?action=offer&agent=991784&product=package&language=de&startDate=${datePackage.value[0]}&endDate=${datePackage.value[1]}&departures=${selectedDeparture.value}&adults=${selectedPeoplePackage.value}&minRecommrate=0&minPrice=0&hSort=price&sortType=up&iframe=1`
    })

    return {
      de,
      planeIcon,
      calendarIcon,
      departureFlights,
      searchTermDeparture,
      selectedDepartureNameValue,
      searchFlightsDeparture,
      getDataDepartureFlight,
      selectFlightDeparture,
      searchTermPackageFrom,
      selectPackageFrom,
      searchPackageFrom,
      selectedPackageNameValueFrom,
      selectedArrivalNameValue,
      searchTermArrival,
      searchFlightsArrivals,
      packagesData,
      getDataPackageFrom,
      datePackage,
      setDatePackage,
      selectedPeoplePackage,
      searchTermArrivalNameValue,
      finalUrlPackage,
      flightTargets,
      selectFlightArrival,
      selectArrivalName,
      toggleDropDown,
      dropDown,
      today,
      minDate
    }
  }
}
</script>
<style></style>